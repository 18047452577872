import { Button, createStyles, Grid, makeStyles, TextField, Theme } from "@material-ui/core";
import MaterialTable from "material-table";
import { useCallback, useRef, useState } from "react";
import { BasetextApi, ErrorHandler } from "system/ApiService";
import { TABLEICONS } from "system/Constants";
import { useLoadingDispatch } from "system/LoadingContext";
import { MaterialTableOptions } from "system/types";

interface SourceBasetextTableProps {
  options: MaterialTableOptions;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      float: "right",
      marginLeft: "10px",
    },
    iconButton: {
      padding: "5px",
    },
    buttonGroup: {
      textAlign: "end",
    },
    root: {
      display: "flex",
      alignItems: "flex-end",
      gap: theme.spacing(2),
    },
  })
);

function SourceBasetextTable({ options }: SourceBasetextTableProps) {
  // 전체, 부분일치 여부 관리하는 상태값
  const [isWholeMatch, setIsWholeMatch] = useState(false);

  // isWholeMatch 상태값 변경 함수
  const handleWholeMatch = () => {
    setIsWholeMatch(!isWholeMatch);
  };

  const classes = useStyles();

  const loading = useLoadingDispatch();

  const downloadExcelSummary = () => {
    loading({ type: "LOADING" });
    BasetextApi.DownloadXLSX("")
      .then((res) => {
        window.location.href = res.data.download_url;
      })
      .catch((e) => {
        let msg = ErrorHandler(e);
        console.log(msg);
      })
      .finally(() => {
        loading({ type: "COMPLETE" });
      });
  };

  const [data, setData] = useState<any[]>([]);

  interface TextSetItem {
    managed_text: string;
    lang_code: string;
  }

  interface ApiDataItem {
    text_code: string;
    text_set: TextSetItem[][];
    matchesSearch: boolean;
    matchesRow: boolean;
  }

  // #region Basetext 정렬
  // 우선순위 정보 배열
  const prefixPriority = ["IM", "RE", "KR", "AA", "OP"];

  // Prefix 비교 
  function comparePrefixes(aPrefix: string, bPrefix: string): number {
    const aPrefixIndex = prefixPriority.indexOf(aPrefix);
    const bPrefixIndex = prefixPriority.indexOf(bPrefix);

    if (aPrefixIndex !== -1 && bPrefixIndex !== -1) return aPrefixIndex - bPrefixIndex;
    if (aPrefixIndex !== -1) return -1;
    if (bPrefixIndex !== -1) return 1;
    return aPrefix.localeCompare(bPrefix);
  }

  // 숫자 비교
  function compareNumbers(aNumber: string, bNumber: string): number {
    const aNum = parseInt(aNumber, 10);
    const bNum = parseInt(bNumber, 10);
    return bNum - aNum;
  }

  // Suffix 비교
  function compareSuffixes(aSuffix: string, bSuffix: string): number {
    if (!aSuffix && bSuffix) return -1;
    if (aSuffix && !bSuffix) return 1;
    if (aSuffix && bSuffix) {
      const aSuffixNum = parseInt(aSuffix, 10);
      const bSuffixNum = parseInt(bSuffix, 10);
      return aSuffixNum - bSuffixNum;
    }
    return 0;
  }

  // #endRegion

  // API를 Search tab
  const transformData = useCallback(
    (rawData: ApiDataItem[], searchText: string) => {
      return (
        rawData
          .map((item) => {
            const row: { [key: string]: string } = {
              text_code: item.text_code,
            };
            let matchesRow = true;
            item.text_set[0].forEach((text) => {
              row[text.lang_code] = text.managed_text;
              if (text.lang_code === "EN_EN" && text.managed_text.includes(searchText)) {
                item.matchesSearch = !isWholeMatch || text.managed_text === searchText;
                matchesRow = matchesRow && (!isWholeMatch || text.managed_text === searchText);
              } else if (text.lang_code === "KO" && text.managed_text.includes(searchText)) {
                item.matchesSearch = !isWholeMatch || text.managed_text === searchText;
                matchesRow = matchesRow && (!isWholeMatch || text.managed_text === searchText);
              }
              item.matchesRow = matchesRow;
              console.log(item.matchesRow);
            });
            if (matchesRow) {
              return row;
            } else {
              return undefined;
            }
          })
          // isWholeMatch가 true일 때, 검색어와 완벽하게 일치하는 row만 반환
          .filter((item) => item)
          //
          .sort((a, b) => {
            if (!a) return 1;
            if (!b) return -1;

            // textCode에 따른 정렬
            const [aPrefix, aNumber, aSuffix = ""] = a.text_code.split("_");
            const [bPrefix, bNumber, bSuffix = ""] = b.text_code.split("_");

            // 3.1 Prefix 비교
            const prefixCompare = comparePrefixes(aPrefix, bPrefix);
            if (prefixCompare !== 0) return prefixCompare;

            // 3.2 Number 비교
            const numberCompare = compareNumbers(aNumber, bNumber);
            if (numberCompare !== 0) return numberCompare;

            // 3.3 Suffix 비교
            return compareSuffixes(aSuffix, bSuffix);
          })
      );
    },
    [isWholeMatch]
  );

  const handleButtonClick = useCallback(() => {
    const searchWord = searchWordRef.current?.value || "";
    if (searchWord.length < 2) {
      alert("Input at least 2 characters.");
      return;
    }

    BasetextApi.GetBasetextSourceSearchByPartialtext(searchWord)
      .then((res) => {
        setData([]);
        const transformedData = transformData(res.data, searchWord);
        setData(transformedData);
      })
      .catch((e) => {
        let msg = ErrorHandler(e);
        console.log(msg);
      });
  }, [transformData]);

  const searchWordRef = useRef<HTMLInputElement>(null);

  const commonStyle = { flex: 1, maxWidth: "200px", minWidth: "100px" };

  const columnsData = [
    { title: "Text Code", field: "text_code" },
    { title: "Korean", field: "KO" },
    { title: "English", field: "EN_EN" },
    { title: "Arabic", field: "AR" },
    { title: "Bulgarian", field: "BG" },
    { title: "Chinese", field: "CN" },
    { title: "Croatian", field: "HR" },
    { title: "Czech", field: "CS" },
    { title: "Danish", field: "DA" },
    { title: "Dutch", field: "NL" },
    { title: "Estonian", field: "ET" },
    { title: "Finnish", field: "FI" },
    { title: "French", field: "FR_FR" },
    { title: "French(American)", field: "FR_US" },
    { title: "Irish", field: "GA" },
    { title: "German", field: "DE" },
    { title: "Greek(Greece)", field: "EL" },
    { title: "Hebrew", field: "IW" },
    { title: "Hindi", field: "HI" },
    { title: "Hungarian", field: "HU" },
    { title: "Italian", field: "IT" },
    { title: "Japanese", field: "JA" },
    { title: "Latvian", field: "LV" },
    { title: "Lithuanian", field: "LT" },
    { title: "Malay", field: "MS" },
    { title: "Maltese", field: "MT" },
    { title: "Norwegian", field: "NO" },
    { title: "Polish", field: "PL" },
    { title: "Portuguese", field: "PT" },
    { title: "Portuguese(Brazil)", field: "BR" },
    { title: "Romanian", field: "RO" },
    { title: "Russian", field: "RU" },
    { title: "Serbian", field: "SR" },
    { title: "Slovak", field: "SK" },
    { title: "Slovenian", field: "SL" },
    { title: "Spanish", field: "ES_SP" },
    { title: "Spanish(Mexico)", field: "ES_MX" },
    { title: "Swedish", field: "SV" },
    { title: "Taiwanese", field: "TW" },
    { title: "Tamil", field: "TA" },
    { title: "Telugu", field: "TE" },
    { title: "Thai", field: "TH" },
    { title: "Turkish", field: "TR" },
    { title: "Ukrainian", field: "UK" },
    { title: "USA", field: "EN_US" },
    { title: "Vietnamese", field: "VI" },
  ];

  const WHOLE_COUNTRY_COLUMNS = columnsData.map((column) => ({
    ...column,
    cellStyle: commonStyle,
    headerStyle: commonStyle,
  }));

  // 체크박스용 버튼 클래스 - 체크했을 때 빨간색 테두리
  const checkboxButtonClass = `inline-flex items-center justify-center px-4 py-2 font-medium border ${
    isWholeMatch ? "border-red-800 text-gray-900 bg-white" : "border-gray-300 text-gray-700 bg-white hover:bg-gray-50"
  } rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2`;

  return (
    <Grid container spacing={2} style={{ minWidth: "1000px" }}>
      <Grid item xs={12}>
        <h4>Download basetext DB</h4>
        <Button variant="contained" color="primary" onClick={downloadExcelSummary}>
          Export Excel
        </Button>
        <br />
        <br />
      </Grid>

      <Grid item xs={12}>
        <h4>Search basetext</h4>
        <div className={classes.root}>
          <TextField
            label="Input search text..."
            inputRef={searchWordRef}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleButtonClick();
              }
            }}
          />

          {/*<Button variant="contained" color="primary" onClick={handleWholeMatch}>*/}
          {/*  {isWholeMatch ? "Whole Match" : "Partial Match"}*/}
          {/*</ Button>*/}

          {/*<Button variant="contained" color="primary" onClick={handleButtonClick}>*/}
          {/*  Search*/}
          {/*</Button>*/}

          <div className="flex space-x-2 items-center">
            {/* 커스텀 체크박스 버튼 */}
            <button className={checkboxButtonClass} onClick={handleWholeMatch}>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  checked={isWholeMatch}
                  onChange={handleWholeMatch}
                  className={`mr-2 h-4 w-4 ${isWholeMatch ? "accent-red-800" : ""}`}
                  onClick={(e) => e.stopPropagation()}
                />
                Whole Match
              </div>
            </button>

            {/* Search 버튼 */}
            <Button variant="contained" color="primary" onClick={handleButtonClick}>
              Search
            </Button>
          </div>
        </div>
        <br></br>

        <MaterialTable
          title="Search results"
          icons={TABLEICONS}
          data={data}
          columns={WHOLE_COUNTRY_COLUMNS}
          options={{
            search: true,
            paging: true,
            pageSize: 5,
            columnsButton: true,
          }}
        />
      </Grid>
    </Grid>
  );
}

export default SourceBasetextTable;
