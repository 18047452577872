import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import jwt_decode from "jwt-decode";
import { UserApi } from "./ApiService";
import { DecodedToken } from "./types";
import {Cookies} from "react-cookie";

declare module "axios" {
  interface AxiosResponse<T = any> extends Promise<T> {}
}

abstract class _HttpClient {
  protected readonly instance: AxiosInstance;
  public constructor() {
    const API_URL = process.env.REACT_APP_API_URL || `http://192.168.15.229:45000/api/v1/`;

    const baseURL = API_URL;

    // baseURL을 기반으로 axios instance를 생성
    this.instance = axios.create({
      baseURL,
    });
    // todo: cors에러로 인해 잠시 주석처리
    // this.instance.defaults.withCredentials = true;
  }
}

export default class HttpClient extends _HttpClient {
  protected accessToken: string | null;
  protected refreshToken: string | null;
  protected multipartFormData: boolean;
  protected isRefreshing: boolean = false;
  
  public constructor() {
    super();
    this.accessToken = null;
    this.refreshToken = null;
    this.multipartFormData = false;
    this.instance.interceptors.request.use(this.requestInterceptor, (error) => Promise.reject(error));
  }

  public getExpirationDateFromToken = (accessToken: string): Date => {
    const decodedToken: DecodedToken = jwt_decode(accessToken);
    // console.log(decodedToken, new Date(decodedToken.exp * 1000));
    // 'exp'는 초 단위 UNIX timestamp이므로, 밀리초로 변환하기 위해 1000을 곱합니다.
    return new Date(decodedToken.exp * 1000);
  };

  private getRefreshToken = () => {
    var cookieValue = null;
    if (document.cookie) {
      let cookies = document.cookie.replace(" ", "").split(";");
      cookies.forEach((cookie) => {
        if (cookie.indexOf("refreshToken=") > -1) {
          cookieValue = cookie.replace("refreshToken=", "");
        }
      });
    }
    return cookieValue;
  };
// 인터셉터에서 리디렉션 전에 URL 저장하기
  private redirectToLogin() {
    // 현재 페이지 URL을 저장 (쿼리 파라미터와 해시 포함)
    const currentPath = window.location.pathname + window.location.search + window.location.hash;
    // URL이 로그인 페이지가 아닌 경우에만 저장
    if (!currentPath.includes('/login')) {
      sessionStorage.setItem('redirectAfterLogin', currentPath);
      console.log(`Saved redirect URL: ${currentPath}`);
    }
    // 토큰 정보 삭제
    this.accessToken = null;
    const cookies = new Cookies();
    cookies.remove("refreshToken", { path: '/' }); // path를 지정해야 정확히 제거됨
    // 로그인 페이지로 리디렉션
    window.location.href = '/login';
  }
  private requestInterceptor = async (config: AxiosRequestConfig) => {
    // 1. 로그인 및 리프레시 관련 엔드포인트는 토큰 체크에서 제외
    // const isAuthEndpoint =
    //   config.url === "/auth/login" ||
    //   config.url === "/auth/refresh_access_token";

    // 2. 리프레시 토큰 요청일 경우 Authorization 헤더 제거
    if (config.url === "auth/refresh_access_token") {
      config.headers["Authorization"] = "";
      return config;
    }
    
    this.refreshToken = this.getRefreshToken();

    // 3. 로그인이 필요 없는 공개 엔드포인트 목록 정의
    const publicEndpoints = ["/auth/login", "/users/register"];
    const isPublicEndpoint = publicEndpoints.some(endpoint => config.url?.includes(endpoint));

    // 4. 액세스 토큰 만료 체크
    if (this.accessToken) {
      const now = new Date();
      const twentyMinutesAfter = new Date(now.getTime() + 20 * 60 * 1000);
      const tokenExpiration = this.getExpirationDateFromToken(this.accessToken);

      // 토큰이 20분 이내에 만료될 예정이고, 현재 리프레시 중이 아니라면 리프레시 진행
      if (tokenExpiration && tokenExpiration < twentyMinutesAfter && !this.isRefreshing) {
        this.isRefreshing = true; // 리프레시 플래그 설정
        console.log("Refresh Started.");

        try {
          const tokenData = await UserApi.Refresh();
          UserApi.SetToken(tokenData.access_token);
          console.log("Refresh solved");
        } catch (e) {
          console.log("Token refresh failed:", e);
          // 로그인 페이지로 리다이렉트
          if (e.response && (e.response.status === 401 || e.response.status === 403)) {
            this.redirectToLogin();
            return Promise.reject(new Error("Authentication required"));
          }
        } finally {
          this.isRefreshing = false; // 리프레시 플래그 해제
        }
      }
    }

    // 5. 보호된 엔드포인트 접근 시 인증 상태 확인
    // 5a. 리프레시 토큰이 없으면 로그인 페이지로 리다이렉트 (액세스 토큰이 있더라도)
    if (!isPublicEndpoint && !this.refreshToken) {
      console.log(`No refresh token available for protected endpoint: ${config.url}`);
      this.redirectToLogin();
      return Promise.reject(new Error("Refresh token not available, authentication required"));
    }

    // 5b. 액세스 토큰이 없고 리프레시 토큰만 있는 경우도 체크
    if (!isPublicEndpoint && !this.accessToken && this.refreshToken) {
      console.log(`Access token missing but refresh token exists for: ${config.url}`);
      // 여기서는 리다이렉트하지 않고, 위의 리프레시 로직이 실행되도록 함
    }

    // 6. 액세스 토큰이 있으면 요청 헤더에 추가
    if (this.accessToken && this.accessToken.length >= 1) {
      config.headers["Authorization"] = `Bearer ${this.accessToken}`;
    }

    // 7. 기타 헤더 설정
    if (this.multipartFormData) {
      config.headers["enctype"] = `multipart/form-data`;
    }

    config.headers["Access-Control-Allow-Credentials"] = `true`;

    return config;
  };

  // if (this.accessToken && this.getExpirationDateFromToken(`${this.accessToken}`) < twentyMinutesAfter) {
  //   console.log("Refresh Started.");
  //   let count = 0;
  //   UserApi.Refresh()
  //     .then((tokenData) => {
  //       console.log(tokenData);
  //       const decoded: any = jwt_decode(tokenData.access_token);
  //       UserApi.SetToken(tokenData.access_token);
  //       console.log("refresh solved");

  //       if (config.url === "auth/refresh_access_token") {
  //         config.headers["Authorization"] = "";
  //       } else if (this.accessToken !== null && this.accessToken.length >= 1) {
  //         config.headers["Authorization"] = `Bearer ${this.accessToken}`;
  //       }

  //       if (this.multipartFormData) {
  //         config.headers["enctype"] = `multipart/form-data`;
  //       }

  //       config.headers["Access-Control-Allow-Credentials"] = `true`;

  //       return config;
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     })
  //     .finally(() => {});
  // } else {
  //   if (config.url === "auth/refresh_access_token") {
  //     config.headers["Authorization"] = "";
  //   } else if (this.accessToken !== null && this.accessToken.length >= 1) {
  //     config.headers["Authorization"] = `Bearer ${this.accessToken}`;
  //   }

  //   if (this.multipartFormData) {
  //     config.headers["enctype"] = `multipart/form-data`;
  //   }

  //   config.headers["Access-Control-Allow-Credentials"] = `true`;

  //   return config;
  // }

  post<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return new Promise((resolve: (value: T) => void, reject: (value: AxiosError) => void) => {
      this.instance
        .post<T>(url, data, config)
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  }

  put<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return new Promise((resolve: (value: T) => void, reject: (value: AxiosError) => void) => {
      this.instance
        .put<T>(url, data, config)
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  }

  delete<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return new Promise((resolve: (value: T) => void, reject: (value: AxiosError) => void) => {
      this.instance
        .delete<T>(url, {
          ...config,
          data: data,
        })
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  }

  get<T = any>(url: string, config?: AxiosRequestConfig) {
    return new Promise((resolve: (value: T) => void, reject: (value: AxiosError) => void) => {
      this.instance
        .get<T>(url, config)
        .then((res) => resolve(res.data))
        .catch((error) => reject(error));
    });
  }

  public setToken(newToken: string) {
    if (newToken.length >= 1) {
      this.accessToken = newToken;
    } else {
      throw new Error("ArgumentException token");
    }
  }

  public setRefreshToken(newToken: string) {
    if (newToken.length >= 1) {
      this.refreshToken = newToken;
    } else {
      throw new Error("ArgumentException token");
    }
  }

  public disableToken() {
    this.accessToken = null;
  }

  public setMultipartFormData() {
    this.multipartFormData = true;
  }
  public unSetMultipartFormData() {
    this.multipartFormData = false;
  }
}
