import { AxiosError } from "axios";
import { Cookies } from "react-cookie";
import HttpClient from "system/http-client";
import {
  Baselanguage,
  Group,
  ImageTextData,
  MainProject,
  Product,
  SearchBasetextHistory,
  SearchTextCode,
  SearchTranstext,
  SourceBasetext,
  SourceGlossary,
  SourceTextOutput,
  SubProject,
  TargetBasetext,
  TargetGlossary,
  TranslateProject,
  UserInfo,
} from "system/types";

import jwt_decode from "jwt-decode";

// *************************************************************
//                        User
// *************************************************************
class _UserApi {
  private client: HttpClient;
  private token: string;
  public constructor(client: HttpClient) {
    this.client = client;
    this.token = "";
  }

  AddUser(userInfo: UserInfo) {
    userInfo.locale = "global";
    return client.post("users", userInfo);
  }
  GetUserList() {
    return client.get(`users`);
  }
  GetUserDetail(userId: string) {
    return client.get(`users/${userId}`);
  }
  GetGroupListByUserId(userId: string) {
    return client.get(`users/group_list/${userId}`);
  }
  UpdateUser(userInfo: UserInfo) {
    return client.put(`users/${userInfo.user_id}`, userInfo);
  }
  Refresh() {
    // const access_token = UserApi.GetToken();
    const cookies = new Cookies();
    const refresh_token = cookies.get("refreshToken");
    return client.post<{
      access_token: string;
      refresh_token: string;
    }>("auth/refresh_access_token", {
      refresh_token: refresh_token,
    });
  }
  Logout() {
    return client.post("user/logout").then((x) => {
      this.token = "";
    });
  }
  SetToken(token: string) {
    this.token = token;
    client.setToken(token);
  }
  DisableToken() {
    client.disableToken();
  }
  GetToken() {
    return this.token;
  }
  GetProfile(userId: string) {
    return this.client.get(`user/profile/${userId}`);
  }
  ChangePassword(userId: string, currentPassword: string, newPassword: string, confirmPassword: string) {
    return this.client.put(`user/change-password/${userId}`, {
      currentPassword: currentPassword,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    });
  }
  ResetPassword(data: FormData) {
    return client.post(`user/reset-password`, data);
  }
  DeleteUser(id: string) {
    return client.delete(`users/${id}`);
  }
}

class _AuthApi {
  private client: HttpClient;
  private token: string;
  public constructor(client: HttpClient) {
    this.client = client;
    this.token = "";
  }
  Login(userId: string, userPassword: string) {
    return client.post<{
      userInfo: UserInfo;
      access_token: string;
      refresh_token: string;
    }>("/auth/login", { user_id: userId, password: userPassword });
  }
  Logout() {
    const access_token = UserApi.GetToken();
    const cookies = new Cookies();
    const refresh_token = cookies.get("refreshToken");
    console.log(access_token, refresh_token, " is my tokens");
    return client.post("/auth/logout", { access_token: access_token, refresh_token: refresh_token });
  }
}

// *************************************************************
//                        Group
// *************************************************************
class _GroupApi {
  private client: HttpClient;
  private token: string;
  public constructor(client: HttpClient) {
    this.client = client;
    this.token = "";
  }

  AddGroup(group_role: number, trans_lang_code: String) {
    return client.post(`groups`, {
      group_role,
      trans_lang_code,
    });
  }
  UpdateGroup(group: Group) {
    console.log(group);
    return client.put<{
      group_role: number;
      trans_lang_code: String;
    }>(`groups/${group.id}`, {
      group_role: group.group_role,
      trans_lang_code: group.trans_lang_code,
    });
  }
  DeleteGroup(id: number) {
    return client.delete(`groups/${id}`);
  }
  GetGroupList() {
    return client.get(`groups`);
  }
  GetGroupDetail(id: number) {
    return client.get(`groups/${id}`);
  }
  Assign(group_id: number, user_id: string) {
    return client.post(`groups/assign/${group_id}`, {
      user_id: user_id,
    });
  }
}

// *************************************************************
//                        Glossary
// *************************************************************
class _GlossaryApi {
  private client: HttpClient;
  private token: string;
  public constructor(client: HttpClient) {
    this.client = client;
    this.token = "";
  }
  // *************************************************************
  //                        Source Glossary
  // *************************************************************
  AddSourceGlossary(sourceGlossary: SourceGlossary) {
    return client.post(`glossary/srcglossary`, {
      glossary_text: sourceGlossary.glossary_text,
      lang_code: sourceGlossary.lang_code,
    });
  }
  GetSourceGlossaryList() {
    return client.get(`glossary/srcglossary`);
  }
  GetTargetGlossaryListBySourceGlossaryId(glossary_id: number) {
    return client.get(`glossary/srcglossary/translated/${glossary_id}`);
  }
  GetSourceGlossaryDetail(glossary_id: number) {
    return client.get(`glossary/srcglossary${glossary_id}`);
  }
  UpdateSourceGlossary(sourceGlossary: SourceGlossary) {
    return client.put(`glossary/srcglossary/${sourceGlossary.id}`, {
      lang_code: sourceGlossary.lang_code,
      glossary_text: sourceGlossary.glossary_text,
    });
  }
  DeleteSourceGlossary(glossary_id: number) {
    return client.delete(`glossary/srcglossary/${glossary_id}`);
  }

  // *************************************************************
  //                        Target Glossary
  // *************************************************************
  AddTargetGlossary(targetGlossary: TargetGlossary) {
    return client.post(`glossary/tgtglossary`, {
      glossary_text: targetGlossary.glossary_text,
      lang_code: targetGlossary.lang_code,
      src_glossary_id: targetGlossary.src_glossary_id,
    });
  }
  GetTargetGlossaryList() {
    return client.get(`glossary/tgtglossary`);
  }
  GetTargetGlossaryDetail(glossary_id: number) {
    return client.get(`glossary/tgtglossary${glossary_id}`);
  }
  UpdateTargetGlossary(targetGlossary: TargetGlossary) {
    return client.put(`glossary/tgtglossary/${targetGlossary.id}`, {
      lang_code: targetGlossary.lang_code,
      glossary_text: targetGlossary.glossary_text,
    });
  }
  DeleteTargetGlossary(target_id: number) {
    return client.delete(`glossary/tgtglossary/${target_id}`);
  }
}

// *************************************************************
//                        Basetext
// *************************************************************
class _BasetextApi {
  private client: HttpClient;
  private token: string;
  public constructor(client: HttpClient) {
    this.client = client;
    this.token = "";
  }
  // *************************************************************
  //                        Source Basetext
  // *************************************************************
  AddSourceBasetext(sourceBasetext: SourceBasetext) {
    return client.post(`basetext/basetext_source`, {
      basetext_text: sourceBasetext.basetext_text,
      lang_code: sourceBasetext.lang_code,
    });
  }
  GetSourceBasetextList() {
    return client.get(`basetext/basetext_source`);
  }
  GetTargetBasetextListBySourceBasetextId(basetext_id: number) {
    return client.get(`basetext/basetext_source/translated/${basetext_id}`);
  }
  GetSourceBasetextDetail(basetext_id: number) {
    return client.get(`basetext/basetext_source${basetext_id}`);
  }
  UpdateSourceBasetext(sourceBasetext: SourceBasetext) {
    return client.put(`basetext/basetext_source/${sourceBasetext.id}`, {
      lang_code: sourceBasetext.lang_code,
      basetext_text: sourceBasetext.basetext_text,
    });
  }
  DeleteSourceBasetext(basetext_id: number) {
    return client.delete(`basetext/basetext_source/${basetext_id}`);
  }

  // 기준문장(EN_EN or KO) 기반으로 번역 문장을 검색
  GetTextToTranstext(searchTranstext: SearchTranstext) {
    //return client.postAsync("basetext/basetext_source/search/text_to_transtext", searchTranstext);
    return client.post("basetext/basetext_source/search/text_to_transtext", searchTranstext);
  }
  // 기준문장(EN_EN or KO) 기반으로 Textcode를 검색
  GetTextToTextcode(searchTextCode: SearchTextCode) {
    return client.post("basetext/basetext_source/search/text_to_textcode", searchTextCode);
  }

  // 부분 검색어를 이용한 번역문 검색
  GetBasetextSourceSearchByPartialtext(searchPartialtext: String) {
    console.log(searchPartialtext);
    return client.post("basetext/basetext_source/search/partialtext", { managed_text: searchPartialtext });
  }

  // XLSX 다운로드
  DownloadXLSX(download_type: String) {
    return client.post("basetext/basetext_source/download_xlsx/", { download_type: download_type });
  }

  // Basetext History
  GetBasetextHistory(searchBasetextHistory: SearchBasetextHistory) {
    // return client.post("/basetext​/_abceeeorssttux / search / basetext_history", { SearchBasetextHistory//
    return client.post("/basetext/basetext_source/search/basetext_history", {
      lang_code: searchBasetextHistory.lang_code,
      text_code: searchBasetextHistory.text_code,
    });
  }
  // *************************************************************
  //                        Target Basetext
  // *************************************************************
  AddTargetBasetext(targetBasetext: TargetBasetext) {
    return client.post(`basetext/basetext_target`, {
      basetext_text: targetBasetext.basetext_text,
      lang_code: targetBasetext.lang_code,
      src_basetext_id: targetBasetext.src_basetext_id,
    });
  }
  GetTargetBasetextList() {
    return client.get(`basetext/basetext_target`);
  }
  GetTargetBasetextDetail(basetext_id: number) {
    return client.get(`basetext/basetext_target${basetext_id}`);
  }
  UpdateTargetBasetext(targetBasetext: TargetBasetext) {
    return client.put(`basetext/basetext_target/${targetBasetext.id}`, {
      lang_code: targetBasetext.lang_code,
      basetext_text: targetBasetext.basetext_text,
    });
  }
  DeleteTargetBasetext(basetext_id: number) {
    return client.delete(`basetext/basetext_target/${basetext_id}`);
  }
}

// *************************************************************
//                        BaseInfo
// *************************************************************
class _BaseInfoApi {
  private client: HttpClient;
  private token: string;
  public constructor(client: HttpClient) {
    this.client = client;
    this.token = "";
  }

  // *************************************************************
  //                        Baselanguage
  // *************************************************************
  AddBaselanguage(baselanguage: Baselanguage) {
    return client.post<{ baselanguage: Baselanguage; token: String }>(`baseinfo/baselanguage`, baselanguage);
  }
  GetBaselanguageList() {
    return client.get(`baseinfo/baselanguage`);
  }
  GetBaselanguageDetail(languageCode: string) {
    return client.get(`baseinfo/baselanguage/${languageCode}`);
  }
  UpdateBaselanguage(baselanguage: Baselanguage) {
    return client.put<{ lang_name_korean: string; lang_name_english: string; token: string }>(
      `baseinfo/baselanguage/${baselanguage.lang_code}`,
      {
        lang_name_korean: baselanguage.lang_name_korean,
        lang_name_english: baselanguage.lang_name_english,
      }
    );
  }
  DeleteBaselanguage(lang_code: string) {
    return client.delete(`baseinfo/baselanguage/${lang_code}`);
  }

  // *************************************************************
  //                        Inbodyproduct
  // *************************************************************
  AddProduct(product: Product) {
    product.product_name = product.product_code;
    return client.post<{ product: Product; token: String }>(`baseinfo/inbodyproduct`, product);
  }
  GetProductList() {
    return client.get(`baseinfo/inbodyproduct`);
  }
  GetProductDetail(product_code: string) {
    return client.get(`baseinfo/inbodyproduct/${product_code}`);
  }
  UpdateProduct(product: Product) {
    return client.put<{ product_name: string; token: string }>(`baseinfo/inbodyproduct/${product.product_code}`, {
      product_name: product.product_name,
    });
  }
  DeleteProduct(lang_code: string) {
    return client.delete<{ token: string }>(`baseinfo/inbodyproduct/${lang_code}`);
  }
}

// *************************************************************
//                        Project
// *************************************************************
class _ProjectApi {
  private client: HttpClient;
  private token: string;

  public constructor(client: HttpClient) {
    this.client = client;
    this.token = "";
  }

  // *************************************************************
  //                        Main Project
  // *************************************************************
  AddMainProject(mainProject: MainProject) {
    return client.post(`project/mainproject`, mainProject);
  }
  GetMainProjectList() {
    return client.get(`project/mainproject`);
  }
  GetMainProjectDetail(main_project_code: string) {
    return client.get(`project/mainproject/${main_project_code}`);
  }
  UpdateMainProject(mainProject: MainProject) {
    return client.put<{ mainProject: MainProject; token: String }>(
      `project/mainproject/${mainProject.id}`,
      mainProject
    );
  }
  DeleteMainProject(main_project_code: number) {
    return client.delete<{ token: string }>(`project/mainproject/${main_project_code}`);
  }
  FinishMainProject(main_project_code: number) {
    return client.delete<{ token: string }>(`project/mainproject/${main_project_code}?project_finish=true`);
  }

  // *************************************************************
  //                        Sub Project
  // - 카테고리 과제에 관련된 API 모음
  // *************************************************************

  AddSubProject(subProject: SubProject) {
    return client.post<{ subproject_id: string; token: string }>(`project/subproject`, subProject);
  }

  GetSubProjectList() {
    return client.get(`project/subproject`);
  }
  GetSubProjectDetail(main_project_code: string) {
    return client.get(`project/subproject/${main_project_code}`);
  }
  GetSubProjectListByMainProjectId(mainId: number) {
    return client.post(`project/mainproject/${mainId}`);
  }
  UpdateSubProject(subProject: SubProject) {
    return client.put<{ subProject: SubProject; token: String }>(`project/subproject/${subProject.id}`, subProject);
  }
  DeleteSubProject(sub_project_code: number) {
    return client.delete<{ token: string }>(`project/subproject/${sub_project_code}`);
  }

  FinishSubProject(sub_project_code: number) {
    return client.delete<{ token: string }>(`project/subproject/${sub_project_code}?project_finish=true`);
  }

  // 각 페이지에 들어가는 이미지-텍스트쌍의 리스트를 받도록 변경한 API
  GetListSource(sub_project_id: number, source_lang_code: string, pageNum: number) {
    return client.post(`project/subproject/list_sources_with_pageno`, {
      // project_id를 string으로 전달받고 있어, 문자열로 변환
      project_id: sub_project_id + "",
      source_lang_code: source_lang_code,
      page_number: pageNum,
    });
  }

  // 각 페이지에 들어가는 이미지-텍스트쌍의 리스트를 받도록 변경한 API
  GetListSourceForPhotoshop(sub_project_id: number, source_lang_code: string, pageNum: number) {
    return client.post(`project/subproject/list_sources_with_pageno_for_photoshop`, {
      project_id: sub_project_id + "",
      source_lang_code: source_lang_code,
      page_number: pageNum,
    });
  }

  FileUploadSubProject(file: File, project_code: string, languageCode: string) {
    let newProjectCode = project_code + "";
    let formData = new FormData();
    formData.append("file", file);
    let data = client.post(`project/subproject/upload/${newProjectCode}/${languageCode}`, formData);
    return data;
  }
  DesignFileUploadSubProject(file: File, project_code: string, design_file_version: string) {
    let newProjectCode = project_code + "";
    let formData = new FormData();
    formData.append("file", file);
    let data = client.post(`project/subproject/designfile/upload/${newProjectCode}/${design_file_version}`, formData);
    return data;
  }
  GetSourceTextBySubProject(subProjectId: number) {
    return client.get(`project/subproject/sourcetext/searchtext/${subProjectId}`);
  }
  PostSourceTextBySubProject(data: SourceTextOutput) {
    return client.post(`project/subproject/sourcetext`, data);
  }
  PutSourceTextBySubProject(textId: number, data: SourceTextOutput) {
    return client.put(`project/subproject/sourcetext/${textId}`, data);
  }
  DeleteSourceTextBySubProject(textId: number) {
    return client.delete(`project/subproject/sourcetext/${textId}`);
  }
  // DesignFile 다운로드
  DownloadDesignFile(designfile_id: String) {
    return client.post(`project/subproject/designfile/download/${designfile_id}`);
  }

  // *************************************************************
  //                        Translate project
  // *************************************************************
  AddTranslateProject(translateProject: TranslateProject) {
    return client.post<{ translateProject: TranslateProject; token: string }>(
      `project/translateproject`,
      translateProject
    );
  }
  AddTargetText(imageTextData: ImageTextData, translateProject: TranslateProject, user: UserInfo, type: string) {
    return client.post(`project/translateproject/targettext`, {
      target_sentence: imageTextData.target_sentence,
      source_type: type,
      sourcetext_id: imageTextData.source_id,
      trans_user_id: user.id,
      target_lang_code: translateProject.target_lang_code,
      translate_projects_id: translateProject.id,
      // is_rejected가 none 타입일 수 있다
      is_rejected: imageTextData.is_rejected === true,
    });
  }
  UpdateTargetText(imageTextData: ImageTextData, translateProject: TranslateProject, user: UserInfo, type: string) {
    return client.put(`project/translateproject/targettext/${imageTextData.target_id}`, {
      target_sentence: imageTextData.target_sentence,
      source_type: type,
      sourcetext_id: imageTextData.source_id,
      trans_user_id: user.id,
      target_lang_code: translateProject.target_lang_code,
      translate_projects_id: translateProject.id,
      // is_rejected가 none 타입일 수 있다
      is_rejected: imageTextData.is_rejected === true,
    });
  }
  UpdateTargetTextBySourceId(
    textDataTarget: ImageTextData,
    translateProject: TranslateProject,
    user: UserInfo,
    type: string
  ) {
    return client.put(`project/translateproject/targettext/target_text_info_by_src/`, {
      sourcetext_id: textDataTarget.source_id,
      target_lang_code: translateProject.target_lang_code,
      translate_projects_id: translateProject.id,
      target_sentence: textDataTarget.target_sentence,
    });
  }
  GetTargetTextBySourceId(sourcetext_id: number, target_lang_code: string, translate_projects_id: number) {
    return client.post(`project/translateproject/targettext/target_text_info_by_src/`, {
      sourcetext_id: sourcetext_id,
      target_lang_code: target_lang_code,
      translate_projects_id: translate_projects_id,
    });
  }
  GetTranslateProjectList() {
    return client.get(`project/translateproject`);
  }
  GetTranslateProjectListBySubProjectId(subId: number) {
    return client.post(`project/subproject/${subId}`);
  }
  GetTranslateProjectDetail(translate_project_code: string) {
    return client.get(`project/translateproject/${translate_project_code}`);
  }

  GetTranslateProjectCompleteHistory(translate_project_code: number) {
    return client.get(`project/translateproject/finish_history/${translate_project_code}`);
  }

  UpdateTranslateProject(translateProject: TranslateProject) {
    return client.put<{ translateProject: TranslateProject; token: String }>(
      `project/translateproject/${translateProject.id}`,
      translateProject
    );
  }
  DeleteTranslateProject(translate_project_code: number) {
    return client.delete<{ token: string }>(`project/translateproject/${translate_project_code}`);
  }
  // [번역가] 번역 프로젝트 마감
  CloseTranslateProject(translate_project_code: number) {
    return client.post<{ token: string }>(`project/translateproject/translate_finish/${translate_project_code}`);
  }
  // [관리자] 번역 프로젝트 마감 및 번역 문장으로 기존 번역문장으로 저장
  FinishTranslateProject(translate_project_code: number) {
    return client.post<{ token: string }>(`project/translateproject/project_finish/${translate_project_code}`);
  }
  // sub project, translate project에 해당하는 이미지 + 원본문장 + 번역문장 데이터를 불러온다.
  GetListSourceTarget(sub_project_id: number, source_lang_code: string, target_lang_code: string, pageNum: number) {
    console.log(sub_project_id, source_lang_code, target_lang_code, pageNum);
    return client.post(`project/translateproject/list_source_target`, {
      project_id: sub_project_id + "",
      src_lang_code: source_lang_code,
      tgt_lang_code: target_lang_code,
      page_number: pageNum,
    });
  }

  // sub project, translate project에 해당하는 이미지 + 원본문장 + 번역문장 데이터를 불러온다.
  GetListSourceTargetAllInOne(
    sub_project_id: number,
    source_lang_code: string,
    target_lang_code: string,
    pageNum: number
  ) {
    return client.post(`project/translateproject/list_source_target_all_in_one`, {
      project_id: sub_project_id + "",
      src_lang_code: source_lang_code,
      tgt_lang_code: target_lang_code,
      page_number: pageNum,
    });
  }
  // sub project, translate project에 해당하는 이미지 + 원본문장 + 번역문장 데이터를 불러온다.
  GetListSourceTargetAllInOneForPhotoshop(
    sub_project_id: number,
    source_lang_code: string,
    target_lang_code: string,
    pageNum: number
  ) {
    return client.post(`project/translateproject/list_source_target_all_in_one_for_photoshop`, {
      project_id: sub_project_id + "",
      src_lang_code: source_lang_code,
      tgt_lang_code: target_lang_code,
      page_number: pageNum,
    });
  }

  GetTranslateProjectListByGroupId(group_id: number) {
    return client.get(`project/translateproject/groups/${group_id}`);
  }

  // 언어 및 Sub Project 정보를 통해서 원문 리소스의 페이지 수 조회
  getMaxSourcePageNumber(project_id: string, source_lang_code: string) {
    return client.post(`project/subproject/get_max_source_page_no`, {
      project_id: project_id,
      source_lang_code: source_lang_code,
    });
  }

  // 언어 및 Sub Project 정보를 통해서 원문 리소스의 페이지 수 조회
  getSubProjectDesignFileList(project_id: string) {
    return client.post(`project/subproject/designfile/list/${project_id}`);
  }

  // 이미 마감된 프로젝트를 번역가에게 오픈해준다.
  ReopenProject(project_code: number) {
    return client.post(`project/translateproject/project_reopen/${project_code}`);
  }

  // 모든 번역 문장 쌍을 받음
  getTranslateCompareSourceTargetByProjectCode(project_code: number) {
    return client.post(`/project/translateproject/translate_compare_source_target/${project_code}`);
  }

  // 페이지메모 저장 API
  addPagememo(translate_project_id: number, page_no: number, memo_contents: string) {
    return client.post(`/project/translateproject/pagememo/`, {
      translate_project_id: translate_project_id,
      page_no: page_no,
      memo_contents: memo_contents,
    });
  }

  // 페이지메모 조회 API
  getPagememo(translate_project_id: number, page_no: number) {
    return client.get(`/project/translateproject/pagememo/${translate_project_id}/${page_no}`);
  }

  // 프로젝트 내 전체 페이지메모 조회 API
  getWholePagememo(translate_project_id: number) {
    return client.get(`/project/translateproject/pagememo/${translate_project_id}/`);
  }

  // 원문-Basetext 번역 XLSX 다운로드
  DownloadBasetextXLSX(translate_project_id: number) {
    return client.post("project/translateproject/download_xlsx/", { translate_project_id: translate_project_id });
  }
}
// *************************************************************
//                        Chat GPT
// *************************************************************
class _ChatGPTApi {
  private client: HttpClient;
  private token: string;

  public constructor(client: HttpClient) {
    this.client = client;
    this.token = "";
  }

  // 언어 및 Sub Project 정보를 통해서 원문 리소스의 페이지 수 조회
  getChatGPTText(managed_text: string, src_lang_code: string, target_lang_code: string) {
    return client.post(`chatgpt/search/text`, {
      managed_text: managed_text,
      src_lang_code: src_lang_code,
      target_lang_code: target_lang_code,
    });
  }
}

const client = new HttpClient();

export const AuthApi = new _AuthApi(client);
export const GroupApi = new _GroupApi(client);
export const UserApi = new _UserApi(client);
export const BaseInfoApi = new _BaseInfoApi(client);
export const GlossaryApi = new _GlossaryApi(client);
export const BasetextApi = new _BasetextApi(client);
export const ProjectApi = new _ProjectApi(client);
export const ChatGPTApi = new _ChatGPTApi(client);

export function ErrorHandler(error: AxiosError): string {
  let msg = "";
  if (error.response) {
    msg += error.response.data;
    if (msg === "" || msg === "[object Object]") {
      //여전히 빈 상태라면...
      switch (error.response.status) {
        case 401:
          msg = "Session Error";
          window.location.replace("/");
          break;
        case 400: //Bad Request
          msg = "Wrong Data Has Been Entered";
          break;
        case 403: //Forbidden
          msg = "Cannot Access";
          break;
        case 404: //NotFound
          msg = "No Data to Show";
          break;
        case 405: //method not allowd
          msg = "405; Cannot Access";
          break;
        case 500:
          msg = "Server Error";
          if (error.response) {
            console.log(error.response, error.response.data);
            // 토큰이 만료된 경우에, 새로운 Access Token 발급받아서 저장
            if (error.response.data.message === "Signature has expired") {
              UserApi.Refresh()
                .then((tokenData) => {
                  console.log(tokenData);
                  const decoded: any = jwt_decode(tokenData.access_token);
                  UserApi.SetToken(tokenData.access_token);

                  UserApi.GetUserDetail(decoded.sub.user_id)
                    .then((res) => {
                      UserApi.SetToken(tokenData.access_token);
                      console.log("refresh solved");
                    })
                    .catch((e) => {
                      let msg = ErrorHandler(e);
                      console.log(msg);
                    });
                })
                .catch((e) => {
                  console.log(e);
                })
                .finally(() => {});
            }
          }
          break;
        default:
          msg = "Unknown Error. Please Contact the Administrator";
          break;
      }
    }
  } else if (error.request) {
    msg += "No Response from the Server";
  }
  console.log(error, msg, "detected error");
  return msg;
}
