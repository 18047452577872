import { ActionButton } from "components/Common/Button";
import SourceTargetMatchingModalContent from "components/Common/SourceTargetMatchingModalContent";
import TitleDiv from "components/Common/TitleDiv";
import MaterialTable from "material-table";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { MyRoutes } from "routes/Routes";
import { BaseInfoApi, ErrorHandler, ProjectApi, UserApi } from "system/ApiService";
import { IsFirstVisitAtom, TranslateProjectAtom } from "system/atom"; 
import { PROJECT_TYPE, TABLEICONS } from "system/Constants";
import { Baselanguage, INIT_TRANSLATE_PROJECT, SourceTargetMatching, TranslateProject } from "system/types";
import { useUserState } from "system/UserContext";
import { checkValidArray } from "system/util";//

import MemoModalContent from "components/Common/MemoModalContent";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

function TranslatorProjectTable() {
  const history = useHistory();
  const [translateProjectList, setTranslateProjectList] = useState<TranslateProject[]>([]);
  const [baselanguageList, setBaselanguageList] = useState<Baselanguage[]>([]);
  const [, setTranslateProject] = useRecoilState(TranslateProjectAtom);

  // main, sub 정보도 포함되어있는 translate project
  const [sourceTargetTranslateProject, setSourceTargetTranslateProject] =
    useState<TranslateProject>(INIT_TRANSLATE_PROJECT);
  const baseLangLookup: Object = baselanguageList.reduce((x, y) => ({ ...x, [y.lang_code]: y.lang_name_english }), {});
  const user = useUserState();
  const { t } = useTranslation();

  // 번역문장 Preview를 위한 코드

  const [, setModalContent] = useState<SourceTargetMatching[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useMemo(() => {
    BaseInfoApi.GetBaselanguageList()
      .then((res) => {
        setBaselanguageList(res.data);
      })
      .catch((e) => {
        let msg = ErrorHandler(e);
        console.log(msg);
      });
  }, []);

  useEffect(() => {
    // id로 권한찾아서, 권한으로부터 번역프로젝트 LIST 생성
    UserApi.GetGroupListByUserId(user.user_id)
      .then((res) => {
        ProjectApi.GetTranslateProjectListByGroupId(res.data[0].id)
          .then((res) => {
            setTranslateProjectList(
              (res.data = res.data.filter(
                (x: any) =>
                  !(x.is_closed_main_project || x.is_closed_sub_project) &&
                  x.project_name_main_project !== "None" &&
                  x.project_name_sub_project !== "None"
              ))
            );
          })
          .catch((e) => {
            let msg = ErrorHandler(e);
            console.log(msg);
          });
      })
      .catch((e) => {
        let msg = ErrorHandler(e);
        console.log(msg);
      });
  }, [user.user_id]);

  // 코드를 기반으로, 언어 정보 검색
  function matchLanguage(langCode: string, languageData: Baselanguage[]) {
    const matchedLanguage = languageData.find((language: Baselanguage) => {
      return language.lang_code === langCode;
    });

    return matchedLanguage;
  }

  // 여러 번역 문장 후보 중에서 가장 첫번째 문장만 찾아서 반환
  function findOneTranslateSentence(originalData: SourceTargetMatching[]) {
    return originalData.map((entry) => {
      return {
        ...entry,
        target_sentence: entry.target_sentences[0] ? entry.target_sentences[0].target_sentence : "",
      };
    });
  }

  // 번역문장 Preview 조회를 위한 API
  const onShowPreview = async (translateProject: TranslateProject) => {
    try {
      if (translateProject && translateProject.id) {
        ProjectApi.getTranslateCompareSourceTargetByProjectCode(translateProject.id)
          .then((res) => {
            BaseInfoApi.GetBaselanguageList()
              .then((languageData) => {
                setSourceTargetTranslateProject(translateProject);
                res.data.sort((a: SourceTargetMatching, b: SourceTargetMatching) => a.page_no - b.page_no);
                if (checkValidArray(res.data)) {
                  res.data[0].target_sentence_langcode = translateProject.target_lang_code;

                  // 원문/번역언어명 매칭
                  res.data[0].source_sentence_lang_info = matchLanguage(
                    res.data[0].source_sentence_langcode,
                    languageData.data
                  );
                  res.data[0].target_sentence_lang_info = matchLanguage(
                    res.data[0].target_sentence_langcode,
                    languageData.data
                  );
                }

                setModalContent(findOneTranslateSentence(res.data));

                setIsModalOpen(true);
              })
              .catch((e) => {
                console.log(e);
              })
              .finally();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (error) {
      console.error("API 호출 중 오류 발생:", error);
    }
  };

  function useModal() {
    const [isOpen, setIsOpen] = useState(false);

    const open = () => setIsOpen(true);
    const close = () => setIsOpen(false);
    const toggle = () => setIsOpen((prev) => !prev);

    return { isOpen, open, close, toggle };
  }

  const memoModal = useModal();

  const [open, setOpen] = React.useState(false);
  const handleDataCheck = (hasData: boolean) => {
    if (!hasData) {
      setOpen(true); // hasData가 false일 때 Snackbar를 띄웁니다.
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [, setIsFirstVisit] = useRecoilState<boolean>(IsFirstVisitAtom);

  return (
    <div>
      <TitleDiv title={"" + t("assignment")}></TitleDiv>
      <br />
      <MaterialTable
        title=""
        icons={TABLEICONS}
        onRowClick={(event, rowData) => {
          if (!rowData) return;
          setTranslateProject(rowData);
          setIsFirstVisit(() => {
            return true;
          });
          history.push({
            pathname: MyRoutes.translatorProject + "/" + rowData.id,
            state: {
              type: rowData.project_type,
              closed: rowData.is_closed,
              main_name: rowData.project_name_main_project,
              sub_name: rowData.project_name_sub_project,
            },
          });
        }}
        columns={[
          {
            title: "" + t("project-name"),
            field: "project_name_main_project",
            cellStyle: { width: "10%" },
            defaultGroupOrder: 1,
          },
          {
            title: "" + t("category-name"),
            field: "project_name_sub_project",
            cellStyle: { width: "10%" },
          },
          {
            title: "" + t("product-code"),
            field: "product_code",
            cellStyle: { width: "10%" },
          },
          { title: "" + t("project-type"), field: "project_type", cellStyle: { width: "10%" }, lookup: PROJECT_TYPE },
          // {
          //   title: "Source Lang",
          //   field: "source_lang_code",
          //   cellStyle: { width: "10%" },
          //   lookup: baseLangLookup,
          // },
          {
            title: "" + t("target-language"),
            field: "target_lang_code",
            cellStyle: { width: "10%" },
            lookup: baseLangLookup,
          },
          { title: "" + t("duedate"), field: "project_duedate", cellStyle: { width: "10%" } },
          {
            title: "" + t("closed-status"),
            field: "is_closed",
            cellStyle: { width: "10%" },
            lookup: { true: "Closed", false: "Opened" },
          },
          {
            title: "",
            field: "",
            cellStyle: { width: "5%" },
            render: (rowData) => {
              return (
                <div className="text-end">
                  <ActionButton
                    style={{ padding: "5px" }}
                    onClick={(event) => {
                      event.stopPropagation();
                      setSourceTargetTranslateProject(() => {
                        return rowData;
                      });
                      memoModal.open();
                    }}
                    title={"Clicking on PREVIEW shows project’s full translations."}
                  >
                    MEMO
                  </ActionButton>
                </div>
              );
            },
          },
          {
            title: "",
            field: "",
            cellStyle: { width: "5%" },
            render: (rowData) => {
              return (
                <div className="text-end">
                  <ActionButton
                    style={{ padding: "5px" }}
                    onClick={(event) => {
                      event.stopPropagation();
                      onShowPreview(rowData);
                    }}
                    title={"Clicking on PREVIEW shows project’s full translations."}
                  >
                    Preview
                  </ActionButton>
                </div>
              );
            },
          },
          {
            title: "",
            field: "",
            cellStyle: { width: "5%" },
            render: (rowData) => {
              return (
                <div className="text-end">
                  <ActionButton
                    style={{ padding: "5px" }}
                    onClick={() => {
                      // options.onRowClick();
                    }}
                  >
                    Translate
                  </ActionButton>
                </div>
              );
            },
          },
        ]}
        data={translateProjectList || []}
        options={{
          filtering: true,
          grouping: true,
          pageSize: 10,
        }}
      />
      <MemoModalContent
        onDataCheck={handleDataCheck}
        _translateProject={sourceTargetTranslateProject}
        isOpen={memoModal.isOpen}
        onClose={() => {
          memoModal.close();
        }}
      ></MemoModalContent>

      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning">
          Memo is empty.
        </Alert>
      </Snackbar>
      {isModalOpen ? (
        <SourceTargetMatchingModalContent
          _translateProject={sourceTargetTranslateProject}
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
        ></SourceTargetMatchingModalContent>
      ) : (
        <></>
      )}
    </div>
  );
}

export default TranslatorProjectTable;
