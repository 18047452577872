import { createStyles, createTheme, makeStyles, Theme } from "@material-ui/core";
import { koKR } from "@material-ui/core/locale";

export const PRIMARY = "#971B2F";
export const SECONDARY = "#5B6770";

export const theme = createTheme(
  {
    typography: {
      h1: {
        fontSize: "3.8rem",
      },
      h2: {
        fontSize: "3.3rem",
      },
      h3: {
        fontSize: "2.5rem",
      },
      h4: {
        fontSize: "2.0rem",
      },
      h5: {
        fontSize: "1.5rem",
        fontWeight: 400,
      },
      h6: {
        fontSize: "1.2rem",
      },
    },
    palette: {
      primary: {
        light: "#666f73",
        main: PRIMARY,
        dark: "#2c3438",
        contrastText: "#fff",
      },
      secondary: {
        light: "#A2AAAD",
        main: SECONDARY,
        dark: "#601317",
        contrastText: "#fff",
      },
    },
  },
  koKR
);

export const MyStyles = makeStyles((theme: Theme) =>
  createStyles({
    // header
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      marginLeft: "10px",
    },
    navProfile: {
      marginRight: "10px",
    },
    // content
    contentWrapper: {
      // minWidth: "1200px",
      marginTop: "10px",
      display: "flex",
      justifyContent: "center",
      flexGrow: 1,
      padding: theme.spacing(2),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: "200ms",
      }),
      marginLeft: 220,
    },
    contentShift: {
      // minWidth: "1200px",
      marginTop: "10px",
      display: "flex",
      justifyContent: "center",
      flexGrow: 1,
      padding: theme.spacing(2),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: "200ms",
      }),
      marginLeft: 0,
    },
    contentBody: {
      zIndex: 7,
      width: "100%",
      "@media (max-width: 1680px)": {
        width: "1640px",
      },
      "@media (max-width: 1440px)": {
        width: "1400px",
      },
      "@media (max-width: 1380px)": {
        width: "1340px",
      },
      "@media (max-width: 1279px)": {
        maxWidth: "100%", // 화면 전체 너비 사용
        minWidth: "240px", // 최소 너비 유지
      },
    },
    // footer
    footer: {
      height: "80px",
      width: "100%",
      padding: "10px",
      position: "absolute",
      marginTop: theme.spacing(3),
      textAlign: "center",
      zIndex: -1,
    },
    bodyWrap: {
      minHeight: "100%",
      position: "relative",
      marginTop: "60px",
      padding: "2px",
    },
    // css 직접
    marginTop10: {
      marginTop: "10px",
    },
    marginBottom10: {
      marginBottom: "10px",
    },
    leftMenuItem: {
      color: "black",
    },
    leftMenuSubItem: {
      marginLeft: "15px",
    },
    fileInput: {
      marginTop: "10px",
      width: "100%",
    },
    leftMenuButton: {
      color: "#007bff",
      padding: "6px",
    },
    preline: {
      whiteSpace: "pre-line",
    },
    marginAuto: {
      margin: "auto",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      fontSize: "0.8rem",
    },
    // TODO : 번역기용 style 조정중
    // popup: {
    //   position: "fixed",
    //   bottom: 0,
    //   left: 0,
    //   width: "11vw",
    //   height: "35vh",
    //   zIndex: 1000, // 충분히 높은 값으로 설정하여 버튼이 상위에 오도록 함
    //   backgroundColor: theme.palette.background.paper,
    //   boxShadow: theme.shadows[5],
    //   // X 버튼을 위한 공간을 만들어줍니다.
    //   paddingTop: "30px",
    // },
    // iframeStyle: {
    //   transform: "scale(0.453)",
    //   transformOrigin: "top left",
    //   width: "220%",
    //   height: "250%",
    //   border: 0,
    // },
    // closeButton: {
    //   position: "absolute",
    //   top: 0,
    //   right: 0,
    //   cursor: "pointer",
    //   fontSize: "10px",
    //   zIndex: 1100,
    // },
  })
);
