import TitleDiv from "components/Common/TitleDiv";
import SourceBasetextTable from "./SourceBasetextTable";
import { Dispatch, ReactElement, SetStateAction, useState } from "react";
import { INIT_MATERIALTABLEOPTIONS, INIT_SOURCE_BASETEXT, MaterialTableOptions, SourceBasetext } from "system/types";
import { useTranslation } from "react-i18next";


function SourceBasetextMain(): ReactElement {
  const [, setSourceBasetext] = useState<SourceBasetext>(INIT_SOURCE_BASETEXT);
  const { t } = useTranslation();
  const options: MaterialTableOptions = {
    ...INIT_MATERIALTABLEOPTIONS,
    rowClick: true,
    add: true,
    onRowClick: (param: SourceBasetext) => {},
  };
  return (
    <>
      <TitleDiv title={t("Basetext DB")}></TitleDiv>
      <br></br>
      <SourceBasetextTable options={options}></SourceBasetextTable>
    </>
  );
}

export default SourceBasetextMain;
