import { Grid } from "@material-ui/core";
import TitleDiv from "components/Common/TitleDiv";
import "date-fns";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { ErrorHandler, ProjectApi } from "system/ApiService";
import { MainProjectAtom, SubProjectAtom, TranslateProjectAtom } from "system/atom";
import { INIT_TRANSLATE_PROJECT, MainProject, SubProject } from "system/types";
import TranslatorComponent from "./TranslatorComponent";
import TranslatorManualComponent from "./TranslatorManualComponent";
import TranslatorVoiceComponent from "./TranslatorVoiceComponent";


interface TranslatorProjectComponentProps {
  _sub?: SubProject;
  _main?: MainProject;
  _finished?: Boolean;
}

export function TranslatorProjectComponent({ _sub, _main, _finished }: TranslatorProjectComponentProps) {
  const [translateProject, setTranslateProject] = useRecoilState(TranslateProjectAtom || INIT_TRANSLATE_PROJECT);

  const location = useLocation();
  const state: any = location.state;
  const closed = state ? state.closed : translateProject?.is_closed;

  const [mainProject, setMainProject] = useRecoilState(MainProjectAtom);
  const [subProject, setSubProject] = useRecoilState(SubProjectAtom);
  const { t } = useTranslation();

  useEffect(() => {
    if (!translateProject.id || translateProject.id === 0) {
      const params = location.pathname.split("/");
      const translate_project_code = params[params.length - 1];

      ProjectApi.GetTranslateProjectDetail(translate_project_code)
        .then((translateRes) => {
          ProjectApi.GetSubProjectDetail(translateRes.data.sub_project_id + "")
            .then((subRes) => {
              if (subRes.data.main_project_id) {
                ProjectApi.GetMainProjectDetail(subRes.data.main_project_id + "")
                  .then((mainRes) => {
                    console.log(mainRes.data, subRes.data, translateRes.data, "mainRes");
                    setMainProject(mainRes.data);
                    setSubProject(subRes.data);
                    setTranslateProject(translateRes.data);
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }
            })
            .catch((e) => {
              let msg = ErrorHandler(e);
              console.log(msg);
            });
        })
        .catch((e) => {
          let msg = ErrorHandler(e);
          console.log(msg);
        });
    } else {
      console.log(translateProject, "translateProject");
      ProjectApi.GetSubProjectDetail(translateProject.sub_project_id + "")
        .then((res) => {
          if (res.data.main_project_id) {
            ProjectApi.GetMainProjectDetail(res.data.main_project_id + "")
              .then((subRes) => {
                setTranslateProject(res.data);
                setSubProject(res.data);
                setMainProject(subRes.data);
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          let msg = ErrorHandler(e);
          console.log(msg);
        });
    }
  }, []);

  function TranslatorProject() {
    switch (subProject?.project_type) {
      case 1:
      case 4:
        return <TranslatorManualComponent></TranslatorManualComponent>;
      case 5:
        if (closed) {
          return <></>;
        } else {
          return <TranslatorVoiceComponent _trans={translateProject}></TranslatorVoiceComponent>;
        }
      default:
        return <TranslatorComponent></TranslatorComponent>;
    }
  }

  return (
    <>
      <Grid container spacing={1} style={{ width:"100%" }}>
        {_finished ? (
          <></>
        ) : (
          <>
            <Grid item xs={12} container>
              <TitleDiv title={"" + t("assignment")}></TitleDiv>
            </Grid>

            <Grid item xs={12} container>
              {mainProject && subProject && translateProject && (
                <div>
                  <b>{mainProject && mainProject.project_name ? mainProject.project_name : "Project"}</b> ＞{" "}
                  <b>{subProject && subProject.project_name ? subProject.project_name : "Category"}</b> ＞{" "}
                  <b>
                    {translateProject && translateProject.target_lang_code ? translateProject.target_lang_code : "LANG"}
                  </b>
                </div>
              )}
            </Grid>
          </>
        )}
        {subProject?.project_type && (
          <Grid item xs={12}>
            {TranslatorProject()}
          </Grid>
        )}
      </Grid>
    </>
  );
}
export default TranslatorProjectComponent;
